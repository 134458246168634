module.exports = {
  siteTitle: 'Dominic M Williams - Fantasy author', // <title>
  manifestName: 'Spectrkkal',
  manifestShortName: 'Landing', // max 12 characters
  manifestStartUrl: '/',
  manifestBackgroundColor: '#663399',
  manifestThemeColor: '#663399',
  manifestDisplay: 'standalone',
  // manifestIcon: 'src/assets/img/website-icon.png',
  pathPrefix: `/`, // This path is subpath of your hosting https://domain/portfolio
  heading: 'Dominic Williams',
  subHeading: '',
  // social
  socialLinks: [
    {
      style: 'brands',
      icon: 'fa-twitter',
      name: 'Twitter',
      url: 'https://twitter.com/DominicMWillia1',
    },
  ],
};
